table {
    page-break-inside: auto;
    border-collapse: collapse;
}
  
tr {
    page-break-inside: avoid;
    page-break-after: auto
    }

thead {
    display: table-header-group
}

tfoot {
    display: table-footer-group
}


@media print {
    .invisiblePrint {
        display: none !important;
    }

    .avoidBreak {
        break-inside: avoid !important;
    }
    .print-container {
        margin-bottom: 20px; /* Space between sections */
    }
}

@page {
    size: auto;
    margin-left: 4mm;
    margin-right: 4mm;
    margin-top: 8mm;
    margin-bottom: 8mm;
  }