
.question-body {
   width: 100%;
   padding-left: 1rem;
   padding-right: 1rem;
   padding-bottom: 1rem;
   padding-top: 0.001rem;

   button {
    margin: 0.5rem;
    margin-bottom: 0;
    margin-top: 0;
   }
}

.alert-stack {
   p {
      display: flex;
      align-items: center;
   }   
}