 
  html {
    box-sizing: border-box;
  }
  
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  
  body {
    color: #242e39;
  
    font-size: 16px;
    line-height: 1.5;
  
    font-family: sans-serif;
    font-size: 16px;
    line-height: 1.6;
  }
  

  p {
    margin: 0;
  }
  
  p + p {
    margin-top: 16px;
  }
  
  .editor {
    position: relative;
  
    width: 100%;
    margin-bottom: 48px;
  }
  
  .menu {
    position: absolute;
    top: 2px;
    left: 2px;
    z-index: 1;
  
    display: flex;
    align-items: center;
    gap: 8px;
  
    width: calc(100% - 4px);
    height: 40px;
    margin: 0;
    padding: 0 8px;
  
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 1px solid #edf1f6;
    background-color: #fff;
    color: #68707d;
  }
  
  .button,
  .menu-button {
    display: flex;
    align-items: center;
    justify-content: center;
  
    height: 32px;
  
    margin: 0;
    padding: 0 8px;
  
    border: 0;
    border-radius: 4px;
    background: transparent;
    color: currentColor;
  
    font-family: inherit;
    font-size: inherit;
    line-height: 1;
    font-weight: inherit;
  
    white-space: nowrap;
    cursor: pointer;
  }
  
  .menu-button {
    width: 32px;
    padding: 0;
  }
  
  .button:hover,
  .button.is-active,
  .menu-button:hover,
  .menu-button.is-active {
    background-color: #edf1f6;
    color: #242e39;
  }
  
  .button:disabled,
  .menu-button:disabled {
    color: #c7cdd4;
  }
  
  
  /* Basic editor styles */
  .ProseMirror {
    margin-top: 4px;
    padding: 48px 8px 8px;
  
    border: 2px solid #c7cdd4;
    border-radius: 4px;

    a {
      cursor: pointer;
    }
  }
  
  .editor-mini .ProseMirror {
    padding-top: 8px;
  }
  
  .ProseMirror-focused {
    border-color: #242e39;
    outline: none;
  }
  
  .ProseMirror > * + * {
    margin-top: 16px;
  }
  
  