@import '../../theme.scss';

.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 10px;
    background-color: $secondarybg-color;
    .card-header {
        font-size: 2rem;
        font-weight: 600;
        padding: 1rem;
    }
    .card-body {
        padding: 1rem;
    }
}

.table-header {
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 1rem;
}

.table-footer {
    display:flex;
    justify-content: flex-end;
    padding: 1rem;
    font-size: 1rem;
    font-weight: 500;
    //background-color: orange;
}

//sort label for table (asc or desc)
.Mui-active .MuiTableSortLabel-icon {
    color: white !important
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 2em;
    aspect-ratio: 1;
    border-radius: 50%;
    background: 
      radial-gradient(farthest-side,#1976d2 94%,#0000) top/5px 5px no-repeat,
      conic-gradient(#0000 30%,#1976d2);
    mask: radial-gradient(farthest-side,#0000 calc(100% - 5px),#000 0);
    animation: l13 0.75s infinite linear;
  }
  @keyframes l13{ 
    100%{transform: rotate(1turn)}
  }