
@media print {
    .invisiblePrint {
        display: none !important;
    }

    .avoidBreak {
        break-inside: avoid !important;
    }
}

.loader {
    width: 2em;
    aspect-ratio: 1;
    border-radius: 50%;
    background: 
      radial-gradient(farthest-side,#1976d2 94%,#0000) top/5px 5px no-repeat,
      conic-gradient(#0000 30%,#1976d2);
    mask: radial-gradient(farthest-side,#0000 calc(100% - 5px),#000 0);
    animation: l13 0.75s infinite linear;
  }
  @keyframes l13{ 
    100%{transform: rotate(1turn)}
  }