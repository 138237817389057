@import '../../../../../theme.scss';

.header {
    font-size: 1.5rem;
    font-weight: 600;
    padding-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card {
    border-radius: 5px;
    background-color: #fcfcfc;
    width: 100%;
    .card-body {
      padding: 1rem;
      .input-row {
        padding: 0.7rem;
        padding-bottom: 0.5rem;
        padding-top: 0rem;
        width: 20rem;
        p{
          margin-top: 0;
          margin-bottom: 5px;
        }
      }
      .info-row {
        padding: 0.7rem;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
        width: 20rem;
      }
    }
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  padding-bottom: 1rem !important;
}


/* Target the scrollbar */
::-webkit-scrollbar {
    width: 11px; /* width of the scrollbar */
    height: 11px;
  }
  
  /* Target the scrollbar thumb (the draggable part) */
  ::-webkit-scrollbar-thumb {
    background: #8b8b8b; /* color of the thumb */
    border-radius: 6px; /* rounded corners for the thumb */
  }
  
  /* Target the scrollbar track (the non-draggable part) */
  ::-webkit-scrollbar-track {
    background: #fcfcfc; /* color of the track */
  }