.UL-editor {
    position: relative;
  
    width: 100%;
    padding-top: 8px;
    margin-bottom: 4px;
    /* Basic editor styles */
    .ProseMirror {
      margin-top: 4px;
      padding: 8px 8px 8px;
    
      border: 2px solid #c7cdd4;
      border-radius: 4px;
      ul {
          padding-left: 16px;
          margin: 8px
      }
    }
    
    .editor-mini .ProseMirror {
      padding-top: 8px;
    }
    
    .ProseMirror-focused {
      border-color: #242e39;
      outline: none;
    }
    
    .ProseMirror > * + * {
      margin-top: 16px;
    }

    .Label {
      color: rgba(0, 0, 0, 0.6);
      background-color: #ffffff;
      position: absolute;
      top: 4px;
      left: 10px;
      z-index: 1;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
