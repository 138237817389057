.settings {
    p{
        margin-top: 0.5rem;
        margin-bottom: 5px;
    }
}

.modalBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    width: auto;
    background-color: #fff;
    box-shadow: 24;
    border-radius: 12px;
    padding: 2rem;
}