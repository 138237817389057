.header {
    font-size: 1.5rem;
    font-weight: 600;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card {
    border-radius: 5px;
    background-color: #fcfcfc;
    display: grid;
    width:auto;
    .card-body {
        padding: 1rem;
        .input-row {
            display: grid;
            padding: 0.7rem;
            padding-bottom: 0.5rem;
            padding-top: 0rem;
        p{
            margin-top: 0;
            margin-bottom: 5px;
        }
        }
        .info-row {
            padding: 0.7rem;
            padding-bottom: 0.5rem;
            padding-top: 0.5rem;
            width: 20rem;
        }
        .multiInput-row {
            display: flex;
            flex-direction: row;
            padding: 0.7rem;
            padding-bottom: 0.5rem;
            padding-top: 0rem;
            width: 20rem;
        }
    }
}

.footer {
    display: flex;
    justify-content: flex-end;
    padding: 2rem;
    padding-top: 0;
  }