.modalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: auto;
  background-color: #fff;
  box-shadow: 24;
  border-radius: 12px;
  width: 90%; 
}

.webcam-container {
  max-height: 90svh;
  display: inline-block;
  justify-content: center;
  align-items: center;
  max-height: 90svh;
}

.webcam {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  max-height: 90svh;
  width: 100%;
}

.capturedImage {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  max-height: 90svh;
  object-fit: contain;
}