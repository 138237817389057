.SigningPad {
    width: max-content;
    canvas {
        width: 25rem;
        height: 15rem;
        border: solid #c7cdd4;;
    }
}

.pointHover:hover {
    background-color: #f5f5f5;    
}

@media print {
    body{
        -webkit-print-color-adjust:exact !important;
        print-color-adjust:exact !important;
    }

    .invsiblePrint {
        display: none !important;
    }
}